import {atom} from "jotai";
import {TermBundle, TermLight} from "../components/Terms/interfaces";
import {NodeInterface} from "../components/Results/interfaces";
import {atomWithReset} from "jotai/utils";

let termUrl = '/api/v1/product_finder/data/terms';
let nodeUrl = '/api/v1/product_finder/data/nodes';

if (process.env.NODE_ENV === 'development') {
  termUrl = process.env.REACT_APP_DEVELOPMENT_URL + termUrl;
  nodeUrl = process.env.REACT_APP_DEVELOPMENT_URL + nodeUrl;
}

// FILTERS
let persisted = localStorage.getItem('sopm_active_filters') ?? '';
let persistedFilterItems = persisted ? JSON.parse(persisted) : [];
let persistedFilterStep = persistedFilterItems?.length  ?? 0;
let finished = Boolean(localStorage.getItem('sopm_filter_result_view') ?? false)

if (persistedFilterStep === 0 && finished) {
  finished = false;
}

export const activeFiltersAtom = atomWithReset<TermLight[]>(persistedFilterItems);
export const availableFilterAtom = atomWithReset<number[]>([]);
export const filterActive = atomWithReset<boolean>(false);
export const currentFilterStepAtom = atomWithReset<number>(persistedFilterStep);
export const finishedAtom = atomWithReset<boolean>(finished);
export const filterReverseAtom = atomWithReset<boolean>(false);
export const backgroundActiveAtom = atomWithReset<boolean>(false);

export const barTitleAtom = atomWithReset('Werbeformate finden');
export const barActionAtom = atomWithReset('');

// TERMS
export const termsBundleAtom = atom<TermBundle[]>((get)=> get(fetchTermsAtom));
export const termUrlAtom = atom(termUrl);
export const fetchTermsAtom = atom(
  async (get) => {
    const response = await fetch(get(termUrlAtom))
    return await response.json();
  }
);

// NODES
export const filteredNodesAtom = atomWithReset<NodeInterface[]>([]);
export const nodeUrlAtom = atom(nodeUrl);
export const nodesAtom = atom<NodeInterface[]>((get)=> get(fetchNodesAtom))
const fetchNodesAtom = atom(
  async (get) => {
    const response = await fetch(get(nodeUrlAtom))
    return await response.json();
  }
);
