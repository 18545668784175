export const colors = {
  white: {
    base: '#fff'
  },
  orange: {
    base: '#fca22c',
    dark: '#DE8209'
  },
  blue: {
    base: '#0068A3',
    90: '#1A77AC',
    80: '#3386B5',
    70: '#4D95BF',
    60: '#66A4C8',
    50: '#80B3D1',
    40: '#99C3DA',
    30: '#B3D2E3',
    20: '#CCE1ED',
    10: '#E6F0F6',
     5: '#F2F7FA'
  },
  yellow: {
    base: '#FED135',
    90: '#FFCE52',
    80: '#FFD361',
    70: '#FFD972',
    60: '#FFDD84',
    50: '#FFE396',
    40: '#FFE9AA',
    30: '#FFEEBE',
    20: '#FFF4D4',
    10: '#FFF9E9',
     5: '#FFFDF4'
  },
  slate: {
    base: '#3B3B3B',
    90: '#454545',
    80: '#575757',
    70: '#6B6B6B',
    60: '#7E7E7E',
    50: '#929292',
    20: '#D2D2D2',
    10: '#E8E8E8',
     5: '#F3F3F3'
  },
  gradients: {
    yellow: 'linear-gradient(180deg, rgba(254, 209, 53, 0.15) 0%, rgba(254, 209, 53, 0) 100%)',
    orange: 'linear-gradient(180deg, rgba(252, 162, 44, 0.15) 0%, rgba(252, 162, 44, 0) 100%)',
    blue: 'linear-gradient(180deg, rgba(0, 104, 163, 0.15) 0%, rgba(0, 104, 163, 0) 100%)',
    white: 'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%)'
  }
}

export default colors;
