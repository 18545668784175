import React from "react";
import Finder from "./components/Finder/Finder";
import styled from "styled-components/macro";
import BackgroundOverlay
  from "./components/BackgroundOverlay/BackgroundOverlay";

export const AppDiv = styled.div`
  font-family: 'Helvetica Now Text W01 Regular', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1280px;
  margin: 0 auto;
  overflow-X: hidden;
  position: relative;
  z-index: 3;
  font-size: 18px;
  pointer-events: none;
`

export const ResetDiv = styled.div`
  h2 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
  }

  svg, img{
    height: auto;
  }

  a {
    text-decoration: none;
  }
  div {
    box-sizing: border-box;
  }
`;

function App() {
  return (
    <ResetDiv>
      <BackgroundOverlay />
      <AppDiv className="App">
        <Finder />
      </AppDiv>
    </ResetDiv>
  );
}

export default App;
