import React, {lazy, Suspense} from 'react';
import styled from "styled-components/macro";
import colors from "../../styles/variables/colors";
import {motion} from "framer-motion";
import ReactGA from 'react-ga4';

type FinderBarComponent = () => JSX.Element;

const FloatBottom = styled(motion.div)`
  display: flex;
  margin-top: auto;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 20px 0 0;

`;

const FinderStyle = styled.div`
  background-color: ${colors.slate["90"]};
  border-radius: 15px 15px 0 0;
  margin-top: 4px;
  max-width: 100%;
  width: 100%;
  box-shadow: 0px -1px 0px #FFFFFF, 0px -2px 10px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: auto auto 1fr;
  pointer-events: all;
`;

ReactGA.initialize('G-89F9WFJZGD');

const Finder: FinderBarComponent = () => {

  const FinderBar = lazy(() => import('./FinderBar'));
  const ResultPage = lazy(() => import('../Results/ResultPage'));
  const Filters = lazy(() => import('../FilterPages/Filters'));
  const NodeFilter = lazy(() => import('./NodeFilter'));

  return (
    <Suspense fallback={<></>}>
      <FloatBottom>
        <FinderStyle>
          <Suspense fallback={<></>}>
            <FinderBar />
          </Suspense>
          <Suspense fallback={<></>}>
            <Filters />
          </Suspense>
          <Suspense fallback={<></>}>
            <ResultPage />
          </Suspense>
        </FinderStyle>
      </FloatBottom>
      <Suspense fallback={<></>}>
        <NodeFilter />
      </Suspense>
    </Suspense>
  )
}

export default Finder;
